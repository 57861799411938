
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CustomerOutstandingPDCBarChart from '@/components/reports-v2/components/codedWidgets/customer/wls/CustomerOutstandingPDCBarChart.vue';
import CustomerDueWithPDCTable from '@/components/reports-v2/components/codedWidgets/customer/wls/CustomerDueWithPDCTable.vue';
import CustomerDueWithPDCTableAll from '@/components/reports-v2/components/codedWidgets/customer/wls/CustomerDueWithPDCTableAll.vue';
import CustomerDueWithPDCTablePromo from '@/components/reports-v2/components/codedWidgets/customer/wls/CustomerDueWithPDCTablePromo.vue';
import DocumentAgingTable from '@/components/reports-v2/components/codedWidgets/customer/wls/DocumentAgingTable.vue';
import GeneralFilterable from '@/components/reports-v2/components/filterables/GeneralFilterable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import LastSync from '@/components/LastSync.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import moment from 'moment';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import CustomerAgingOverviewTable from '@/components/reports-v2/components/codedWidgets/customer/CustomerAgingOverviewTable.vue';

@Component({
	components: {
		SelectFilter,
		LastSync,
		GeneralFilterable,
		CustomerOutstandingPDCBarChart,
		StickyFilterContainer,
		DateFilter,
		CustomerListFilter,
		DocumentAgingTable,
		CustomerAgingOverviewTable,
		CustomerDueWithPDCTableAll,
		CustomerDueWithPDCTable,
        CustomerDueWithPDCTablePromo,
		AgentsListFilter,
	},
})
export default class WlsCustomerAging extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedAgents: string[] = [];
	public selectedCustomers: string[] = [];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('day').valueOf();
	}

	// public get selectedAgents() {
	// 	return gdbx.allAgents;
	// }

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
