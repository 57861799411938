
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
	CustomerIvDnCard,
	CustomerKoCard,
	PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { GobiColor } from '@/helpers/color';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import { addComma } from '@/util/number';
import { DataViewFormatter } from '../../../elements/charts/helpers/dataViewFormatter';
import moment from 'moment';
import settingx from '@/store/modules/settingx';
import HighlightAccountsToggle from '@/components/HighlightAccountsToggle.vue';
import Checkbox from 'primevue/checkbox';

@Component({
	components: {
		StackedBarChartFilterable,
		TableCollapseToggle,
		HighlightAccountsToggle,
		Checkbox,
	},
})
export default class CustomerOutstandingPDCBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public agingResult: number[] = [];
	public pdcResult: number[] = [];
	public agingAfterPdcResult: number[] = [];

	public series: echarts.ECharts[] | any = [];
	public detailFields: any[] = [
		{
			key: 'total_aging',
			label: 'Total Outstanding',
		},
		{
			key: 'total_pdc',
			label: 'Total PDC',
		},
		{
			key: 'total_aging_after_pdc',
			label: 'Total Outstanding after PDC',
		},
	];

	public isExcludeCurrentAmount: boolean = false;

	public get xAxisData() {
		return !this.isExcludeCurrentAmount
			? [
					'Current Amount',
					'Overdue 0-30',
					'Overdue 31-60',
					'Overdue 61-90',
					'Overdue 91-120',
					'Overdue > 120',
			  ]
			: [
					'Overdue 0-30',
					'Overdue 31-60',
					'Overdue 61-90',
					'Overdue 91-120',
					'Overdue > 120',
			  ];
	}

	public get newSeriesData() {
		const seriesClone = JSON.parse(JSON.stringify(this.series)) as any[];

		if (this.isExcludeCurrentAmount) {
			seriesClone.forEach((item) => {
				item.data!.shift();
			});
		}

		return seriesClone;
	}

	public get periodTotalData() {
		const seriesClone = this.newSeriesData;
		let totalOustanding = 0;
		let totalOutstandingAfterPDC = 0;
		let totalPDC = 0;
		let data: number[] = [];

		seriesClone.forEach((item) => {
			if (item.name === 'Outstanding') {
				data = item.data as number[];
				totalOustanding = data.reduce((a, b) => a + b, 0);
			}

			if (item.name === 'Outstanding after PDC') {
				data = item.data as number[];
				totalOutstandingAfterPDC = data.reduce((a, b) => a + b, 0);
			}

			if (item.name === 'PDC') {
				data = item.data as number[];
				totalPDC = data.reduce((a, b) => a + b, 0);
			}
		});

		return [
			{
				total_aging:
					this.currencySymbol +
					' ' +
					addComma(totalOustanding, gdbx.numDecimal, true),
				total_pdc:
					this.currencySymbol + ' ' + addComma(totalPDC, gdbx.numDecimal, true),
				total_aging_after_pdc:
					this.currencySymbol +
					' ' +
					addComma(totalOutstandingAfterPDC, gdbx.numDecimal, true),
			},
		];
	}

	public get isHighlightAccount() {
		return settingx.now.highlightAccount;
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get dataViewFormatter() {
		return DataViewFormatter.basic;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const {
			selectedAsOfDate,
			selectedCustomers,
			selectedAgents,
			isHighlightAccount,
		} = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedCustomers,
			selectedAgents,
			isHighlightAccount,
		]);
	}

	public async expensiveCalc() {
		// Customer ivdn
		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateAsOf(this.selectedAsOfDate);

		const ivdnCards: CustomerIvDnCard[] = await (
			await ivdnRef.get()
		).getCards();

		const groupedCustomerIvdn = {};

		for (const ivdn of ivdnCards) {
			if (!groupedCustomerIvdn[ivdn.code]) {
				groupedCustomerIvdn[ivdn.code] = [];
			}
			groupedCustomerIvdn[ivdn.code].push(ivdn);
		}

		// Customer ko
		const koRef = FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateAsOf(this.selectedAsOfDate);

		const koCards: CustomerKoCard[] = await (await koRef.get()).getCards();

		const groupedCustomerKo = {};

		for (const ko of koCards) {
			if (!groupedCustomerKo[ko.code]) {
				groupedCustomerKo[ko.code] = [];
			}
			groupedCustomerKo[ko.code].push(ko);
		}

		// Customer ko pdc
		const pdcKoRef = FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.numberRange('koDate', [
				['>', moment().valueOf()],
				['<', Number.POSITIVE_INFINITY],
			]);

		const pdcKoCards: CustomerKoCard[] = await (
			await pdcKoRef.get()
		).getCards();

		const groupedCustomerKoPdc = {};

		for (const ko of pdcKoCards) {
			if (!groupedCustomerKoPdc[ko.code]) {
				groupedCustomerKoPdc[ko.code] = [];
			}
			groupedCustomerKoPdc[ko.code].push(ko);
		}

		this.agingResult = [0, 0, 0, 0, 0, 0];
		this.pdcResult = [0, 0, 0, 0, 0, 0];
		this.agingAfterPdcResult = [0, 0, 0, 0, 0, 0];

		for (const code in groupedCustomerIvdn) {
			if (groupedCustomerIvdn.hasOwnProperty(code)) {
				const cards = groupedCustomerIvdn[code] as CustomerIvDnCard[];

				let koCards2: CustomerKoCard[] = [];

				if (groupedCustomerKo.hasOwnProperty(code)) {
					koCards2 = groupedCustomerKo[code] as CustomerKoCard[];
				}

				let dueSum = 0;

				cards.forEach((i) => {
					if (moment(this.selectedAsOfDate).diff(i.dueDate, 'day') > 0) {
						dueSum += i.amount;
					}
				});

				koCards2.forEach((ko) => {
					if (moment(this.selectedAsOfDate).diff(ko.dueDate, 'day') > 0) {
						dueSum -= ko.amount + ko.gainLoss;
					}
				});

				// let totalCurrent = 0;
				// let totalLess30 = 0;
				// let totalLess60 = 0;
				// let totalLess90 = 0;
				// let totalLess120 = 0;
				// let totalMore120 = 0;

				if (!(this.isHighlightAccount && Math.round(dueSum) === 0)) {
					// Ivdn cards
					cards.forEach((ivdn) => {
						const asOfDate = this.selectedAsOfDate;

						if (moment(asOfDate).diff(ivdn.dueDate, 'day') <= 0) {
							// totalCurrent += ivdn.amount;
							this.agingResult[0] += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 0 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 30
						) {
							// totalLess30 += ivdn.amount;
							this.agingResult[1] += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 30 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 60
						) {
							// totalLess60 += ivdn.amount;
							this.agingResult[2] += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 60 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 90
						) {
							// totalLess90 += ivdn.amount;
							this.agingResult[3] += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 90 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 120
						) {
							// totalLess120 += ivdn.amount;
							this.agingResult[4] += ivdn.amount;
						} else if (moment(asOfDate).diff(ivdn.dueDate, 'day') > 120) {
							// totalMore120 += ivdn.amount;
							this.agingResult[5] += ivdn.amount;
						}
					});

					// Ko cards
					koCards2.forEach((ko) => {
						const asOfDate = this.selectedAsOfDate;

						if (
							moment(asOfDate).diff(ko.dueDate, 'day') <= 0 &&
							ko.koDate <= moment().valueOf()
						) {
							// totalCurrent -= ko.amount + ko.gainLoss;
							this.agingResult[0] -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 0 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 30 &&
							ko.koDate <= moment().valueOf()
						) {
							// totalLess30 -= ko.amount + ko.gainLoss;
							this.agingResult[1] -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 30 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 60 &&
							ko.koDate <= moment().valueOf()
						) {
							// totalLess60 -= ko.amount + ko.gainLoss;
							this.agingResult[2] -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 60 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 90 &&
							ko.koDate <= moment().valueOf()
						) {
							// totalLess90 -= ko.amount + ko.gainLoss;
							this.agingResult[3] -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 90 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 120 &&
							ko.koDate <= moment().valueOf()
						) {
							// totalLess120 -= ko.amount + ko.gainLoss;
							this.agingResult[4] -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 120 &&
							ko.koDate <= moment().valueOf()
						) {
							// totalMore120 -= ko.amount + ko.gainLoss;
							this.agingResult[5] -= ko.amount + ko.gainLoss;
						}
					});

					// let totalPdcLess30 = 0;
					// let totalPdcLess60 = 0;
					// let totalPdcLess90 = 0;
					// let totalPdcLess120 = 0;
					// let totalPdcMore120 = 0;
					// let totalPdcCurrent = 0;

					// Ko pdc cards
					koCards2.forEach((ko) => {
						const asOfDate = this.selectedAsOfDate;

						if (
							moment(asOfDate).diff(ko.dueDate, 'day') <= 0 &&
							ko.koDate >= moment().valueOf()
						) {
							// totalPdcCurrent += ko.amount + ko.gainLoss;
							this.pdcResult[0] += ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 0 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 30 &&
							ko.koDate >= moment().valueOf()
						) {
							// totalPdcLess30 += ko.amount + ko.gainLoss;
							this.pdcResult[1] += ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 30 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 60 &&
							ko.koDate >= moment().valueOf()
						) {
							// totalPdcLess60 += ko.amount + ko.gainLoss;
							this.pdcResult[2] += ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 60 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 90 &&
							ko.koDate >= moment().valueOf()
						) {
							// totalPdcLess90 += ko.amount + ko.gainLoss;
							this.pdcResult[3] += ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 90 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 120 &&
							ko.koDate >= moment().valueOf()
						) {
							// totalPdcLess120 += ko.amount + ko.gainLoss;
							this.pdcResult[4] += ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 120 &&
							ko.koDate >= moment().valueOf()
						) {
							// totalPdcMore120 += ko.amount + ko.gainLoss;
							this.pdcResult[5] += ko.amount + ko.gainLoss;
						}
					});
					// totalPDC +=
					// 	totalPdcCurrent +
					// 	totalPdcLess30 +
					// 	totalPdcLess60 +
					// 	totalPdcLess90 +
					// 	totalPdcLess120 +
					// 	totalPdcMore120;

					// totalOustanding +=
					// 	totalCurrent +
					// 	totalLess30 +
					// 	totalLess60 +
					// 	totalLess90 +
					// 	totalLess120 +
					// 	totalMore120;
				}
			}
		}
		// totalOutstandingAfterPDC += totalOustanding - totalPDC;

		this.agingAfterPdcResult[0] += this.agingResult[0] - this.pdcResult[0];
		this.agingAfterPdcResult[1] += this.agingResult[1] - this.pdcResult[1];
		this.agingAfterPdcResult[2] += this.agingResult[2] - this.pdcResult[2];
		this.agingAfterPdcResult[3] += this.agingResult[3] - this.pdcResult[3];
		this.agingAfterPdcResult[4] += this.agingResult[4] - this.pdcResult[4];
		this.agingAfterPdcResult[5] += this.agingResult[5] - this.pdcResult[5];

		this.series = [
			{
				name: 'Outstanding',
				data: this.agingResult,
				type: 'bar',
				barWidth: '40%',
			},
			{
				name: 'Outstanding after PDC',
				data: this.agingAfterPdcResult,
				type: 'bar',
				barWidth: '15%',
				color: GobiColor.DEEP_TEAL,
				stack: 'a',
			},
			{
				name: 'PDC',
				data: this.pdcResult,
				type: 'bar',
				barWidth: '15%',
				stack: 'a',
			},
		];

		this.saveHistory('series');
	}
}
