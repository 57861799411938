
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import settingx from '@/store/modules/settingx';
import Checkbox from 'primevue/checkbox';

@Component({
	components: { Checkbox },
})
export default class HighlightAccountsToggle extends Vue {
	public localHighlightAccount: boolean = this.globalHighlightAccount;

	public get globalHighlightAccount() {
		return settingx.now.highlightAccount;
	}

	@Watch('localHighlightAccount')
	public onToHightlightAccountChanged(newVal) {
		settingx.updateSettings({ highlightAccount: newVal });
	}

	@Watch('globalHighlightAccount')
	public onGlobalHightlightAccountChanged(newVal) {
		this.localHighlightAccount = newVal;
	}
}
