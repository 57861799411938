
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import BaseFilterable from '../BaseFilterable.vue';
import BaseFilterableContainer from '../BaseFilterableContainer.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';
import gdbx from '@/store/modules/gdbx';
import { addComma } from '@/util/number';

@Component({
	components: {
		BaseFilterable,
		DataInfo,
		DataTable,
		Column,
		InputText,
		Button,
		InputSwitch,
		Dialog,
	},
})
export default class WLSTableFilterable extends BaseFilterableContainer {
	// items & fields
	@Prop({ default: () => [] }) public items!: any[];
	@Prop({ default: () => [] }) public fields!: any[];
	// sorting
	@Prop() public sortField?: string;
	@Prop({ default: true }) public isSortable?: boolean;
	@Prop({ default: -1 }) public sortOrder?: number;
	@Prop({ default: () => [] }) public firstLevelDetailFields!: any[];
	@Prop({ default: false }) public isFixedColumn?: boolean;
	// filtering
	@Prop({}) public filters!: string[];
	// row expand
	@Prop({ default: null }) public expandMode!: 'expand' | 'dialog' | null;
	// edit
	@Prop({ default: false }) public isEditable!: boolean;
	@Prop({ default: () => [] }) public expandedFields!: any[];
	// dialog
	@Prop({ default: () => [] }) public dialogFields!: any[];
	@Prop({}) public dialogEntryKey!: string;
	@Prop({}) public expandColumnKey!: string;
	@Prop({}) public stickyHeader?: number | string;
	@Prop({}) public rowClass?:
		| ((
				item: any,
				type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy',
		  ) => string | string[])
		| string
		| string[];
	@Prop({}) public exportFileName?: string;

	public selectedFilter: any = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	};

	public expandedRows: any[] = [];
	public isDialogVisible: boolean = false;

	public dialogData = {
		title: '',
		outstandingAmount: '',
		unappliedAmount: '',
		pdcAmount: '',
		outstandingAmountAfterPdc: '',
		items: [] as any[],
	};

	public processHeaderName(name: string) {
		const newString = name.replaceAll('_', ' ');
		const finalString = newString
			.toLowerCase()
			.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
		return finalString;
	}

	public formatLocal(option: any, value: any) {
		if (option) {
			return option(value);
		}

		return value;
	}

	public get currency() {
		return gdbx.currencySymbol;
	}
	public get decimal() {
		return gdbx.numDecimal;
	}

	public getDialogData(title: string, items: any) {
		const newItems: any[] = [];

		items.items.forEach((item) => {
			if (this.expandMode === 'expand' && item[this.dialogEntryKey] === title) {
				newItems.push(...item.items);
			}

			if (this.expandMode === 'dialog') {
				newItems.push(item);
			}
		});

		this.dialogData.title = title;
		this.dialogData.items = newItems;
		this.dialogData.outstandingAmount = addComma(
			items.total_outstanding,
			this.decimal,
		);
		this.dialogData.unappliedAmount = addComma(
			items.unapplied_amount,
			this.decimal,
		);
		this.dialogData.pdcAmount = addComma(items.total_pdc, this.decimal);
		this.dialogData.outstandingAmountAfterPdc = addComma(
			items.total_outstanding - items.total_pdc,
			this.decimal,
		);

		this.openDialog();
	}

	public onCellEditComplete(event) {
		const { data, newValue, field } = event;

		switch (field) {
			case 'lead_time_edit':
				if (newValue.trim().length > 0) {
					data[field] = newValue;
					data.reorder_level =
						Math.round(data.lead_time_edit * data.daily_demand * 100) / 100;
					data.recommend_reorder_qty =
						Math.round(
							(Math.round(data.lead_time_edit * data.daily_demand * 100) / 100 -
								data.actual_qty) *
								100,
						) / 100;
				} else {
					event.preventDefault();
				}
				break;

			default:
				if (newValue.trim().length > 0) {
					data[field] = newValue;
				} else {
					event.preventDefault();
				}
				break;
		}
	}

	public exportCSV() {
		if (this.$refs.dt as any) {
			(this.$refs.dt as any).exportCSV();
		}
	}

	public exportExpandCSV() {
		if (this.$refs.dtExpand as any) {
			(this.$refs.dtExpand as any).exportCSV();
		}
	}

	public openDialog() {
		this.isDialogVisible = true;
	}

	public closeDialog() {
		this.dialogData.title = '';
		this.dialogData.items = [];
		this.isDialogVisible = false;
	}

	public get isContainDataInfoName() {
		return this.dataInfoName ? true : false;
	}

	public get isExpandable() {
		if (this.expandMode && this.expandMode === 'expand') {
			return true;
		}

		return false;
	}
}
