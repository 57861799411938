
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import WLSTableFilterable from '@/components/reports-v2/components/filterables/Customize/WLSTableFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	CustomerIvDnCard,
	CustomerKoCard,
	CustomerSupplierDbCard,
	CustomerSupplierTradeCard,
	PermissionsGroup,
} from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import FilterWidget from '../../FilterWidget.vue';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import { addComma } from '@/util/number';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import HighlightAccountsToggle from '@/components/HighlightAccountsToggle.vue';
import settingx from '@/store/modules/settingx';
import Checkbox from 'primevue/checkbox';

@Component({
	components: {
		WLSTableFilterable,
		TableCollapseToggle,
		HighlightAccountsToggle,
		Checkbox,
	},
})
export default class CustomerDueWithPDCTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public tableItems: any[] = [];
	public tableFields: any[] = [];
	public detailFields: any[] = [];

	public isApplyPdc: boolean = false;
	public isShowCreditTerm: boolean = true;
	public isShowDueDetails: boolean = false;

	public periodTotalData: any[] = [];
	public periodTotalFields: any[] = [
		{
			key: 'current_sum',
			label: 'Sum of Current Amount',
		},
		{
			key: 'due_sum',
			label: 'Sum of Due Amount',
		},
		{
			key: 'unapplied_sum',
			label: 'Sum of Unapplied Amount',
		},
		{
			key: 'outstanding_sum',
			label: 'Sum of Total Outstanding',
		},
	];

	public dueSum: number = 0;
	public currentSum: number = 0;
	public outstandingSum: number = 0;
	public unappliedSum: number = 0;

	public format(
		value: number,
		decimal: number = 2,
		forceDecimal: boolean = true,
	) {
		return addComma(value, decimal, forceDecimal);
	}

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');

		return 'Customer Outstanding - Details' + '_' + 'As Of ' + formattedDate;
	}

	public get isHighlightAccount() {
		return settingx.now.highlightAccount;
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get updatedTable() {
		const tableFieldClone = [...this.tableFields];

		const creditPosition = tableFieldClone.findIndex(
			(field) => field.key === 'credit_term',
		);

		const firstDuePosition = tableFieldClone.findIndex(
			(field) => field.key === 'due_less_30',
		);

		const totalDuePosition = tableFieldClone.findIndex(
			(field) => field.key === 'due_amount',
		);

		// const unappliedAmountPosition = tableFieldClone.findIndex(
		// 	(field) => field.key === 'unapplied_amount',
		// );

		if (!this.isShowCreditTerm) {
			tableFieldClone.splice(creditPosition, 1);
		}

		if (!this.isShowDueDetails) {
			tableFieldClone.splice(firstDuePosition, 5);
		}

		if (this.isShowDueDetails) {
			// tableFieldClone.splice(unappliedAmountPosition, 1);
			tableFieldClone.splice(totalDuePosition, 1);
		}

		return tableFieldClone;
	}

	public get expensiveHook() {
		const {
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
			isApplyPdc,
			isHighlightAccount,
		} = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedCustomers,
			isApplyPdc,
			isHighlightAccount,
		]);
	}

	public async expensiveCalc() {
		// Customer ivdn
		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateAsOf(this.selectedAsOfDate);

		const ivdnCards: CustomerIvDnCard[] = await (
			await ivdnRef.get()
		).getCards();

		const groupedCustomerIvdn = {};

		for (const ivdn of ivdnCards.filter((card) => {
            return card.docNo.includes('Promo');
        })) {
			if (!groupedCustomerIvdn[ivdn.code]) {
				groupedCustomerIvdn[ivdn.code] = [];
			}
			groupedCustomerIvdn[ivdn.code].push(ivdn);
		}

		// Global Customer
		const globalCustomerRef = FilteredDatabase.ref('globalCustomers')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const globalCustomerCards: CustomerSupplierDbCard[] = await (
			await globalCustomerRef.get()
		).getCards();

		const groupedGlobalCustomer = {};

		for (const item of globalCustomerCards) {
			if (!groupedGlobalCustomer[item.code]) {
				groupedGlobalCustomer[item.code] = [];
			}
			groupedGlobalCustomer[item.code].push(item);
		}

		// Customer trade
		const tradeRef = FilteredDatabase.ref('customerTrades')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers);

		const customerTradeCards: CustomerSupplierTradeCard[] = await (
			await tradeRef.get()
		).getCards();

		const groupedCustomerTrade = {};

		for (const trade of customerTradeCards) {
			if (!groupedCustomerTrade[trade.code]) {
				groupedCustomerTrade[trade.code] = [];
			}
			groupedCustomerTrade[trade.code].push(trade);
		}

		// Customer ko
		const koRef = FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.dateAsOf(this.selectedAsOfDate);

		const koCards: CustomerKoCard[] = await (await koRef.get()).getCards();

		const groupedCustomerKo = {};

		for (const ko of koCards.filter((card) => {
            return card.toDocNo.includes('Promo');
        })) {
			if (!groupedCustomerKo[ko.code]) {
				groupedCustomerKo[ko.code] = [];
			}
			groupedCustomerKo[ko.code].push(ko);
		}

		// Customer ko pdc
		const pdcKoRef = FilteredDatabase.ref('customerKos')
			.agents(this.selectedAgents)
			.customers(this.selectedCustomers)
			.numberRange('koDate', [
				['>', moment().valueOf()],
				['<', Number.POSITIVE_INFINITY],
			]);

		const pdcKoCards: CustomerKoCard[] = await (
			await pdcKoRef.get()
		).getCards();

		const groupedCustomerKoPdc = {};

		for (const ko of pdcKoCards.filter((card) => {
            return card.toDocNo.includes('Promo');
        })) {
			if (!groupedCustomerKoPdc[ko.code]) {
				groupedCustomerKoPdc[ko.code] = [];
			}
			groupedCustomerKoPdc[ko.code].push(ko);
		}

		const result: Array<{
			company_name: string;
			current_amount: number;
			due_less_30: number;
			due_less_60: number;
			due_less_90: number;
			due_less_120: number;
			due_more_120: number;
			due_amount: number;
			total_outstanding: number;
			total_pdc: number;
			unapplied_amount: number;
			// credit_utilisation: number;
		}> = [];

		for (const code in groupedCustomerIvdn) {
			if (groupedCustomerIvdn.hasOwnProperty(code)) {
				const object = {
					company_name: '',
					current_amount: 0,
					due_less_30: 0,
					due_less_60: 0,
					due_less_90: 0,
					due_less_120: 0,
					due_more_120: 0,
					due_amount: 0,
					total_outstanding: 0,
					total_pdc: 0,
					unapplied_amount: 0,
					// credit_utilisation: 0,
					credit_term: '',
					_cellVariants: {},
					items: [] as any[],
				};

				const detailResult: Array<{
					code: string;
					doc_no: string;
					doc_date: number;
					due_date: number;
					outstanding_amount: number;
					outstanding_after_pdc: number;
					pdc: number;
					days_due: number;
				}> = [];

				const cards = groupedCustomerIvdn[code] as CustomerIvDnCard[];

				let koCards2: CustomerKoCard[] = [];
				let koPdcCards2: CustomerKoCard[] = [];
				let tradeCards: CustomerSupplierTradeCard[] = [];

				if (groupedCustomerTrade.hasOwnProperty(code)) {
					tradeCards = groupedCustomerTrade[
						code
					] as CustomerSupplierTradeCard[];
				}

				if (groupedCustomerKo.hasOwnProperty(code)) {
					koCards2 = groupedCustomerKo[code] as CustomerKoCard[];
				}

				if (groupedCustomerKoPdc.hasOwnProperty(code)) {
					koPdcCards2 = groupedCustomerKoPdc[code] as CustomerKoCard[];
				}

				let dueSum2 = 0;

				cards.forEach((i) => {
					if (moment(this.selectedAsOfDate).diff(i.dueDate, 'day') > 0) {
						dueSum2 += i.amount;
					}
				});

				koCards2.forEach((ko) => {
					if (moment(this.selectedAsOfDate).diff(ko.dueDate, 'day') > 0) {
						dueSum2 -= ko.amount + ko.gainLoss;
					}
				});

				if (!(this.isHighlightAccount && Math.round(dueSum2) === 0)) {
					const detailObject = cards.filter((card) => {
                        return card.docNo.includes('Promo');
                    })
                        .map((i) => {
						const pdcAmount = koPdcCards2
							.filter((ko) => ko.toDocNo === i.docNo)
							.reduce((a, b) => a + b.amount, 0);

						const koAmount = koCards2
							.filter((ko) => ko.toDocNo === i.docNo)
							.filter((ko) => ko.koDate < moment().valueOf())
							.reduce((a, b) => a + b.amount, 0);

						const gainLossAmount = koCards2
							.filter((ko) => ko.toDocNo === i.docNo)
							.filter((ko) => ko.koDate < moment().valueOf())
							.reduce((a, b) => a + b.gainLoss, 0);

						const iResult = {
							code,
							doc_no: i.docNo,
							doc_date: i.date,
                            due_date: moment(i.date)
                                .add(1, 'month')
                                .endOf('month')
                                .valueOf(),
							pdc: pdcAmount,
							outstanding_amount: i.amount - koAmount - gainLossAmount,
							outstanding_after_pdc:
								i.amount - koAmount - gainLossAmount - pdcAmount,
							days_due: moment(this.selectedAsOfDate).diff(moment(i.date)
                                .add(1, 'month')
                                .endOf('month')
                                .valueOf(), 'day'),
						};

						object.total_pdc += pdcAmount;

						return iResult;
					});

					let filteredResult: Array<{
						code: string;
						doc_no: string;
						doc_date: number;
						due_date: number;
						outstanding_amount: number;
						outstanding_after_pdc: number;
						pdc: number;
						days_due: number;
					}> = [];

					filteredResult = detailObject;

					filteredResult = filteredResult.filter(
						(i) => Math.round(i.outstanding_amount) !== 0,
					);

					detailResult.push(...filteredResult);

					object.company_name = gdbx.customerNames[code];

					let totalOutstanding = 0;

					let unappliedAmount = 0;
					unappliedAmount = tradeCards.reduce(
						(a, b) => a + (b.unappliedAmount ? b.unappliedAmount : 0),
						0,
					);
					object.unapplied_amount = unappliedAmount;

					// Ivdn cards
					cards.forEach((ivdn) => {
						const asOfDate = this.selectedAsOfDate;

						if (moment(asOfDate).diff(ivdn.dueDate, 'day') <= 0) {
							object.current_amount += ivdn.amount;
							totalOutstanding += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 0 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 30
						) {
							object.due_less_30 += ivdn.amount;
							object.due_amount += ivdn.amount;
							totalOutstanding += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 30 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 60
						) {
							object.due_less_60 += ivdn.amount;
							object.due_amount += ivdn.amount;
							totalOutstanding += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 60 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 90
						) {
							object.due_less_90 += ivdn.amount;
							object.due_amount += ivdn.amount;
							totalOutstanding += ivdn.amount;
						} else if (
							moment(asOfDate).diff(ivdn.dueDate, 'day') > 90 &&
							moment(asOfDate).diff(ivdn.dueDate, 'day') <= 120
						) {
							object.due_less_120 += ivdn.amount;
							object.due_amount += ivdn.amount;
							totalOutstanding += ivdn.amount;
						} else if (moment(asOfDate).diff(ivdn.dueDate, 'day') > 120) {
							object.due_more_120 += ivdn.amount;
							object.due_amount += ivdn.amount;
							totalOutstanding += ivdn.amount;
						}
					});

					// Ko cards
					koCards2.forEach((ko) => {
						const asOfDate = this.selectedAsOfDate;

						if (
							moment(asOfDate).diff(ko.dueDate, 'day') <= 0 &&
							ko.koDate <= moment().valueOf()
						) {
							object.current_amount -= ko.amount + ko.gainLoss;
							totalOutstanding -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 0 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 30 &&
							ko.koDate <= moment().valueOf()
						) {
							object.due_less_30 -= ko.amount + ko.gainLoss;
							object.due_amount -= ko.amount + ko.gainLoss;
							totalOutstanding -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 30 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 60 &&
							ko.koDate <= moment().valueOf()
						) {
							object.due_less_60 -= ko.amount + ko.gainLoss;
							object.due_amount -= ko.amount + ko.gainLoss;
							totalOutstanding -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 60 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 90 &&
							ko.koDate <= moment().valueOf()
						) {
							object.due_less_90 -= ko.amount + ko.gainLoss;
							object.due_amount -= ko.amount + ko.gainLoss;
							totalOutstanding -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 90 &&
							moment(asOfDate).diff(ko.dueDate, 'day') <= 120 &&
							ko.koDate <= moment().valueOf()
						) {
							object.due_less_120 -= ko.amount + ko.gainLoss;
							object.due_amount -= ko.amount + ko.gainLoss;
							totalOutstanding -= ko.amount + ko.gainLoss;
						} else if (
							moment(asOfDate).diff(ko.dueDate, 'day') > 120 &&
							ko.koDate <= moment().valueOf()
						) {
							object.due_more_120 -= ko.amount + ko.gainLoss;
							object.due_amount -= ko.amount + ko.gainLoss;
							totalOutstanding -= ko.amount + ko.gainLoss;
						}
					});

					object.total_outstanding = totalOutstanding - unappliedAmount;

					let totalPdcLess30 = 0;
					let totalPdcLess60 = 0;
					let totalPdcLess90 = 0;
					let totalPdcLess120 = 0;
					let totalPdcMore120 = 0;
					let totalPdcCurrent = 0;

					let totalOutstandingWithPdc = 0;

					if (this.isApplyPdc) {
						// Ko pdc cards
						koCards2.forEach((ko) => {
							const asOfDate = this.selectedAsOfDate;

							if (
								moment(asOfDate).diff(ko.dueDate, 'day') <= 0 &&
								ko.koDate >= moment().valueOf()
							) {
								totalPdcCurrent += ko.amount + ko.gainLoss;
							} else if (
								moment(asOfDate).diff(ko.dueDate, 'day') > 0 &&
								moment(asOfDate).diff(ko.dueDate, 'day') <= 30 &&
								ko.koDate >= moment().valueOf()
							) {
								totalPdcLess30 += ko.amount + ko.gainLoss;
							} else if (
								moment(asOfDate).diff(ko.dueDate, 'day') > 30 &&
								moment(asOfDate).diff(ko.dueDate, 'day') <= 60 &&
								ko.koDate >= moment().valueOf()
							) {
								totalPdcLess60 += ko.amount + ko.gainLoss;
							} else if (
								moment(asOfDate).diff(ko.dueDate, 'day') > 60 &&
								moment(asOfDate).diff(ko.dueDate, 'day') <= 90 &&
								ko.koDate >= moment().valueOf()
							) {
								totalPdcLess90 += ko.amount + ko.gainLoss;
							} else if (
								moment(asOfDate).diff(ko.dueDate, 'day') > 90 &&
								moment(asOfDate).diff(ko.dueDate, 'day') <= 120 &&
								ko.koDate >= moment().valueOf()
							) {
								totalPdcLess120 += ko.amount + ko.gainLoss;
							} else if (
								moment(asOfDate).diff(ko.dueDate, 'day') > 120 &&
								ko.koDate >= moment().valueOf()
							) {
								totalPdcMore120 += ko.amount + ko.gainLoss;
							}

							totalOutstandingWithPdc =
								totalOutstanding -
								totalPdcLess30 -
								totalPdcLess60 -
								totalPdcLess90 -
								totalPdcLess120 -
								totalPdcMore120 -
								totalPdcCurrent;

							object.total_outstanding =
								totalOutstandingWithPdc - unappliedAmount;
						});

						object.current_amount -= totalPdcCurrent;
						object.due_less_30 -= totalPdcLess30;
						object.due_less_60 -= totalPdcLess60;
						object.due_less_90 -= totalPdcLess90;
						object.due_less_120 -= totalPdcLess120;
						object.due_more_120 -= totalPdcMore120;

						const totalPdcDue =
							totalPdcLess30 +
							totalPdcLess60 +
							totalPdcLess90 +
							totalPdcLess120 +
							totalPdcMore120;

						object.due_amount -= totalPdcDue;

						object._cellVariants[`due_less_30`] =
							totalPdcLess30 > 0 ? 'pdc-applied' : '';
						object._cellVariants[`due_less_60`] =
							totalPdcLess60 > 0 ? 'pdc-applied' : '';
						object._cellVariants[`due_less_90`] =
							totalPdcLess90 > 0 ? 'pdc-applied' : '';
						object._cellVariants[`due_less_120`] =
							totalPdcLess120 > 0 ? 'pdc-applied' : '';
						object._cellVariants[`due_more_120`] =
							totalPdcMore120 > 0 ? 'pdc-applied' : '';
						object._cellVariants[`current_amount`] =
							totalPdcCurrent > 0 ? 'pdc-applied' : '';
						object._cellVariants[`due_amount`] =
							totalPdcDue > 0 ? 'pdc-applied' : '';
						object._cellVariants[`total_outstanding`] =
							totalPdcDue > 0 || totalPdcCurrent > 0 ? 'pdc-applied' : '';
					}

					if (groupedGlobalCustomer.hasOwnProperty(code)) {
						const globalCards = groupedGlobalCustomer[
							code
						] as CustomerSupplierDbCard[];

						// object.credit_utilisation =
						// 	(totalOutstanding /
						// 		(globalCards[0].creditLimit === 0
						// 			? 1
						// 			: globalCards[0].creditLimit)) *
						// 	100;

						// if (this.isApplyPdc) {
						// 	object.credit_utilisation =
						// 		(totalOutstandingWithPdc /
						// 			(globalCards[0].creditLimit === 0
						// 				? 1
						// 				: globalCards[0].creditLimit)) *
						// 		100;
						// }

						object.credit_term = globalCards[0].creditTerm;
					}

					object.items = detailResult;
					result.push(object);
				}
			}
		}

		const dueSum = result.reduce((a, b) => a + b.due_amount, 0);
		const currentSum = result.reduce((a, b) => a + b.current_amount, 0);
		const unappliedSum = result.reduce((a, b) => a + b.unapplied_amount, 0);

		this.dueSum = dueSum;
		this.currentSum = currentSum;
		this.outstandingSum = dueSum + currentSum;
		this.unappliedSum = unappliedSum;

		this.periodTotalData = [
			{
				current_sum:
					this.currency +
					' ' +
					addComma(this.currentSum, gdbx.numDecimal, true),
				due_sum:
					this.currency + ' ' + addComma(this.dueSum, gdbx.numDecimal, true),
				unapplied_sum:
					this.currency +
					' ' +
					addComma(this.unappliedSum, gdbx.numDecimal, true),
				outstanding_sum:
					this.currency +
					' ' +
					addComma(this.outstandingSum, gdbx.numDecimal, true),
			},
		];

		this.tableFields = [
			{ key: 'company_name', sortable: true },
			{
				key: 'credit_term',
				sortable: true,
			},
			{
				key: 'current_amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'due_less_30',
				label: 'Overdue 0-30',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'due_less_60',
				label: 'Overdue 31-60',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'due_less_90',
				label: 'Overdue 61-90',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'due_less_120',
				label: 'Overdue 91-120',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'due_more_120',
				label: 'Overdue > 120',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'due_amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'unapplied_amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'total_outstanding',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			// {
			// 	key: 'credit_utilisation',
			// 	sortable: true,
			// 	formatter: TableItemFormatter.percentageWithoutDecimal,
			// },
		];

		this.detailFields = [
			{ key: 'doc_no', sortable: true },
			{
				key: 'doc_date',
				sortable: true,
				formatter: TableItemFormatter.date3,
			},
			{
				key: 'due_date',
				sortable: true,
				formatter: TableItemFormatter.date3,
			},
			{
				key: 'outstanding_amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'pdc',
				label: 'PDC',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'outstanding_after_pdc',
				label: 'Outstanding After PDC',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'days_due',
				sortable: true,
			},
		];

		this.tableItems = result.filter(
			(item) => Math.round(item.total_outstanding) !== 0,
		);

		this.saveHistory(
			'tableItems',
			'tableFields',
			'detailFields',
			'periodTotalData',
			'detailFields',
		);
	}
}
