
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	CustomerIvDnCard,
	CustomerKoCard,
	PermissionsGroup,
	ValueText,
} from '@/store/models.def';
import FilterWidget from '../../FilterWidget.vue';
import { TableItemFormatter } from '../../../elements/charts/helpers/tableItemFormatter';
import gdbx from '@/store/modules/gdbx';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { addComma } from '@/util/number';

interface DocumentAgingData {
	customer: string;
	docNo: string;
	docDate: number;
	amount: number;
	outstanding: number;
	pdc: number;
	afterPdc: number;
	agingDays: number;
}

@Component({
	components: {
		TableFilterable,
		SelectFilter,
	},
})
export default class DocumentAgingTable extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public selectedAging: string = this.agingList[0].value;

	public get permissionIds(): PermissionsGroup[] {
		return ['customers'];
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get infoList() {
		const totalDocNo = this.tableItems.length;
		const totalOutstanding = addComma(
			this.tableItems.reduce((a, b) => a + b.outstanding, 0),
			this.decimal,
			true,
		);
		const totalPdc = addComma(
			this.tableItems.reduce((a, b) => a + b.pdc, 0),
			this.decimal,
			true,
		);
		const totalAfterPdc = addComma(
			this.tableItems.reduce((a, b) => a + b.afterPdc, 0),
			this.decimal,
			true,
		);

		return { totalDocNo, totalOutstanding, totalPdc, totalAfterPdc };
	}

	public get agingList(): ValueText[] {
		const list = [
			{ text: 'All', value: 'all' },
			{ text: 'B30', value: 'below30' },
			{ text: 'A30', value: 'above30' },
			{ text: 'A60', value: 'above60' },
			{ text: 'A90', value: 'above90' },
			{ text: 'A120', value: 'above120' },
			{ text: 'A150', value: 'above150' },
		];

		return list;
	}

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Customer Aging Overview' + '_' + formattedDate;
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedCustomers, selectedAging } = this;
		return JSON.stringify([selectedAsOfDate, selectedCustomers, selectedAging]);
	}

	public async expensiveCalc() {
		const ivdnRef = FilteredDatabase.ref('customerIvDns')
			.dateAsOf(this.selectedAsOfDate)
			.customers(this.selectedCustomers);

		const koRef = FilteredDatabase.ref('customerKos')
			.dateAsOf(this.selectedAsOfDate)
			.numberRange('koDate', [
				['>', 0],
				['<=', this.selectedAsOfDate],
			])
			.customers(this.selectedCustomers);

		const pdcKoRef = FilteredDatabase.ref('customerKos')
			.dateAsOf(this.selectedAsOfDate)
			.numberRange('koDate', [
				['>', this.selectedAsOfDate],
				['<=', Number.POSITIVE_INFINITY],
			])
			.customers(this.selectedCustomers);

		const ivdnCards: CustomerIvDnCard[] = await (
			await ivdnRef.get()
		).getCards();
		const koCards: CustomerKoCard[] = await (await koRef.get()).getCards();
		const pcdKoCards: CustomerKoCard[] = await (
			await pdcKoRef.get()
		).getCards();

		let result = [] as DocumentAgingData[];

		ivdnCards.forEach((ivdn) => {
			const object = {} as DocumentAgingData;

			const koAmount =
				koCards.filter((ko) => ko.toDocNo === ivdn.docNo).length > 0
					? koCards
							.filter((ko) => ko.toDocNo === ivdn.docNo)
							.reduce((a, b) => a + b.amount, 0)
					: 0;

			const gainLoss =
				koCards.filter((ko) => ko.toDocNo === ivdn.docNo).length > 0
					? koCards.filter((ko) => ko.toDocNo === ivdn.docNo)[0].gainLoss
					: 0;

			const pcdKo =
				pcdKoCards.filter((ko) => ko.toDocNo === ivdn.docNo).length > 0
					? pcdKoCards.filter((ko) => ko.toDocNo === ivdn.docNo)[0].amount
					: 0;

			const pcdGainLoss =
				pcdKoCards.filter((ko) => ko.toDocNo === ivdn.docNo).length > 0
					? pcdKoCards.filter((ko) => ko.toDocNo === ivdn.docNo)[0].gainLoss
					: 0;

			object.customer = gdbx.customerNames[ivdn.code];
			object.docNo = ivdn.docNo;
			object.docDate = ivdn.date;
			object.amount = ivdn.amount;
			object.outstanding = ivdn.amount - koAmount - gainLoss;
			object.pdc = pcdKo + pcdGainLoss;
			object.afterPdc = ivdn.amount - koAmount - gainLoss - pcdKo - pcdGainLoss;
			object.agingDays = moment(this.selectedAsOfDate).diff(
				moment(ivdn.date),
				'days',
			);

			result.push(object);
		});

		result = result.filter((item) => Math.round(item.outstanding) !== 0);

		if (this.selectedAging) {
			switch (this.selectedAging) {
				case 'below30':
					result = result.filter((item) => item.agingDays <= 30);
					break;
				case 'above30':
					result = result.filter(
						(item) => item.agingDays > 30 && item.agingDays <= 60,
					);
					break;
				case 'above60':
					result = result.filter(
						(item) => item.agingDays > 60 && item.agingDays <= 90,
					);
					break;
				case 'above90':
					result = result.filter(
						(item) => item.agingDays > 90 && item.agingDays <= 120,
					);
					break;
				case 'above120':
					result = result.filter(
						(item) => item.agingDays > 120 && item.agingDays <= 150,
					);
					break;
				case 'above150':
					result = result.filter((item) => item.agingDays > 150);
					break;
				case 'all':
					result = result;
					break;
			}
		} else {
			result = [];
		}

		this.tableFields = [
			{ key: 'customer', sortable: true },
			{
				key: 'docNo',
				sortable: true,
			},
			{
				key: 'docDate',
				sortable: true,
				formatter: TableItemFormatter.date3,
			},
			{
				key: 'amount',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'outstanding',
				label: 'Current Outstanding',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'pdc',
				label: 'PDC',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'afterPdc',
				label: 'After PDC',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'agingDays',
				sortable: true,
				formatter: TableItemFormatter.basic,
			},
		];

		this.tableItems = result;

		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
